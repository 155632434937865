var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "grey lighten-3 px-3", attrs: { id: "requestTrial" } },
    [
      _c("section-header", {
        attrs: {
          "sub-header": "Request a trial now",
          text:
            "Please provide your details and we will reach out to you shortly",
          header: "trial"
        }
      }),
      _c(
        "v-card",
        {
          staticClass: "pa-4 mx-auto",
          attrs: { flat: "", outlined: "", "max-width": "500" }
        },
        [
          _c("div", { attrs: { id: "mc_embed_signup" } }, [
            _c(
              "form",
              {
                staticClass: "validate",
                attrs: {
                  action:
                    "https://alphacruncher.us12.list-manage.com/subscribe/post?u=1457dbc3c5be915a28e53e73b&id=07b8187587",
                  method: "post",
                  id: "mc-embedded-subscribe-form",
                  name: "mc-embedded-subscribe-form",
                  target: "_self"
                }
              },
              [
                _c(
                  "div",
                  { attrs: { id: "mc_embed_signup_scroll" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "mc-field-group subtitle-1 primary--text"
                      },
                      [
                        _c("label", { attrs: { for: "mce-EMAIL" } }, [
                          _vm._v(" E-Mail "),
                          _c("span", { staticClass: "asterisk" }, [_vm._v("*")])
                        ]),
                        _c("input", {
                          staticClass: "required email",
                          attrs: {
                            type: "email",
                            value: "",
                            name: "EMAIL",
                            id: "mce-EMAIL",
                            required: ""
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mc-field-group subtitle-1 primary--text"
                      },
                      [
                        _c("label", { attrs: { for: "mce-NAME" } }, [
                          _vm._v("Name")
                        ]),
                        _c("input", {
                          attrs: {
                            type: "text",
                            value: "",
                            name: "NAME",
                            id: "mce-NAME"
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mc-field-group subtitle-1 primary--text"
                      },
                      [
                        _c("label", { attrs: { for: "mce-ORG" } }, [
                          _vm._v("Organization/University")
                        ]),
                        _c("input", {
                          attrs: {
                            type: "text",
                            value: "",
                            name: "ORG",
                            id: "mce-ORG"
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "clear", attrs: { id: "mce-responses" } },
                      [
                        _c("div", {
                          staticClass: "response",
                          staticStyle: { display: "none" },
                          attrs: { id: "mce-error-response" }
                        }),
                        _c("div", {
                          staticClass: "response",
                          staticStyle: { display: "none" },
                          attrs: { id: "mce-success-response" }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { position: "absolute", left: "-5000px" },
                        attrs: { "aria-hidden": "true" }
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "text",
                            name: "b_1457dbc3c5be915a28e53e73b_07b8187587",
                            tabindex: "-1",
                            value: ""
                          }
                        })
                      ]
                    ),
                    _c("v-checkbox", {
                      attrs: { label: "I am faculty member*" },
                      model: {
                        value: _vm.isFaculty,
                        callback: function($$v) {
                          _vm.isFaculty = $$v
                        },
                        expression: "isFaculty"
                      }
                    }),
                    _c("div", { staticClass: "clear" }, [
                      _c("input", {
                        staticClass: "button",
                        style: { opacity: _vm.isFaculty ? 1 : 0.5 },
                        attrs: {
                          type: "submit",
                          value: "Submit",
                          name: "subscribe",
                          id: "mc-embedded-subscribe",
                          disabled: !_vm.isFaculty
                        }
                      })
                    ]),
                    _c("div", { staticClass: "indicates-required" }, [
                      _c("span", { staticClass: "asterisk" }, [_vm._v("*")]),
                      _vm._v("Required fields")
                    ])
                  ],
                  1
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }