<template>
    <section id="requestTrial" class="grey lighten-3 px-3">
        <section-header sub-header="Request a trial now" text="Please provide your details and we will reach out to you shortly" header="trial" />
        <v-card flat outlined class="pa-4 mx-auto" max-width="500">
            <div id="mc_embed_signup">
                <form
                    action="https://alphacruncher.us12.list-manage.com/subscribe/post?u=1457dbc3c5be915a28e53e73b&amp;id=07b8187587"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    class="validate"
                    target="_self"
                >
                    <div id="mc_embed_signup_scroll">
                        <div class="mc-field-group subtitle-1 primary--text">
                            <label for="mce-EMAIL">
                                E-Mail
                                <span class="asterisk">*</span>
                            </label>
                            <input type="email" value name="EMAIL" class="required email" id="mce-EMAIL" required />
                        </div>
                        <div class="mc-field-group subtitle-1 primary--text">
                            <label for="mce-NAME">Name</label>
                            <input type="text" value name="NAME" class id="mce-NAME" />
                        </div>
                        <div class="mc-field-group subtitle-1 primary--text">
                            <label for="mce-ORG">Organization/University</label>
                            <input type="text" value name="ORG" class id="mce-ORG" />
                        </div>
                        <div id="mce-responses" class="clear">
                            <div class="response" id="mce-error-response" style="display:none"></div>
                            <div class="response" id="mce-success-response" style="display:none"></div>
                        </div>
                        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                        <div style="position: absolute; left: -5000px;" aria-hidden="true">
                            <input type="text" name="b_1457dbc3c5be915a28e53e73b_07b8187587" tabindex="-1" value />
                        </div>
                        <v-checkbox v-model="isFaculty" label="I am faculty member*"></v-checkbox>
                        <div class="clear">
                            <input
                                type="submit"
                                value="Submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                class="button"
                                :disabled="!isFaculty"
                                :style="{ opacity: isFaculty ? 1 : 0.5 }"
                            />
                        </div>
                        <div class="indicates-required"><span class="asterisk">*</span>Required fields</div>
                    </div>
                </form>
            </div>
        </v-card>
    </section>
</template>

<script>
export default {
    data: function() {
        return {
            isFaculty: false
        }
    }
}
</script>

<style scoped>
input[type='text'],
input[type='email'] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid black;
}
input[type='submit'] {
    background-color: #19a7c1;
    color: white;
    padding: 16px 32px;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
}
</style>
